<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
    name: "app",
    page: {
        // All subcomponent titles will be injected into this template.
        titleTemplate(title) {
            title = typeof title === "function" ? title(this.$store) : title;
            return title ? `${title} | ${appConfig.title}` : appConfig.title;
        },
    },

    mounted() {
        if (window.PerformanceNavigationTiming) {
            console.info("window.performance works fine on this browser");
            performance.getEntriesByType("navigation").map((res) => {
                if (res.type === "reload") {
                    this.manualPageReload();
                }
            });
        }
        this.refresh();
        document.addEventListener("click", this.refresh);
        document.addEventListener("keypress", this.refresh);
        document.addEventListener("wheel", this.refresh);
    },
    data() {
        return {
            timeout: null,
        };
    },
    methods: {
        test() {
            console.log("mouse moved");
        },
        manualPageReload() {
            this.$store.dispatch(
                "apidata/fetchUserInfo",
                localStorage.cs_user_token
            );
        },
        refresh() {
            clearInterval(this.timeout);
            this.timeout = setInterval(() => {
                this.$router.push("/login");
            }, 15 * 60 * 1000);
        },
    },
};
</script>
