//export const BASE_URL = "https://testapi.eservice-falconcity.com/api/";

// export const BASE_URL = "http://localhost:8889/api/";

export const PAYMENT_URL = 'https://sbcheckout.PayFort.com/FortAPI/paymentPage/';

export const PAYFORT_PAYMENT_LINK = 'https://sbpaymentservices.PayFort.com/FortAPI/paymentApi';

export const PASSWORD_BASE = 'falcon';
export const REQUEST_TYPES = [
	{ id: 1, type: 'Visitor', code: 'CL' },
	{ id: 2, type: 'Mortgage', code: 'RM' },
	{ id: 3, type: 'Amendment', code: 'AP' },
	{ id: 4, type: 'Maintenance ', code: 'MR' },
	{ id: 5, type: 'Immigration', code: 'IN' },
	{ id: 6, type: 'Title Deed', code: 'TD' },
	{ id: 7, type: 'Interim Registration', code: 'NI' },
	{ id: 12, type: 'Home Improvements', code: 'HI' },
	{ id: 17, type: 'Commercial Modifications', code: 'CM' },
	{ id: 20, type: 'Landscaping', code: 'LS' },
	{ id: 21, type: 'Security Deposit Refund', code: 'SDR' },
	{ id: 22, type: 'NOC Revalidation', code: 'NR' },
	{ id: 23, type: 'Built Drawings', code: 'BD' },
	{ id: 24, type: 'Barrier Remote Control', code: 'BRC' },
	{ id: 25, type: 'Trash Bin', code: 'TB' }
];
export const LANDSCAPING_TYPES = [
	'New Landscaping',
	'Renovate Existing',
	'Palm Tree',
	'Interlock',
	'Underground Water Tank',
	'Planting Tress',
	'Rain Garden',
	'Pondless',
	'Waterfalls',
	'Fountain',
	'Irrigation',
	'Tiles Work',
	'Others'
];
export const HOME_IMPROVEMENT_TYPES = [
	'Wall',
	'Floor',
	'Kitchen',
	'Bathroom',
	'Lighting',
	'Electrical',
	'Swimming Pool',
	'Other'
];
export const COMMERICIAL_MODES_TYPES = [
	'Wall',
	'Floor',
	'Kitchen',
	'HVAC',
	'Lighting',
	'Electrical/Mechanical/Plumbing',
	'Other'
];
export const PAYMENT_TYPES = [
	{ id: 1, type: 'full payment' },
	{ id: 2, type: 'mortgage' },
	{ id: 3, type: 'installments' }
];

export const REQUEST_STATUS = [
	{ id: 0, text: 'Pending' },
	{ id: 1, text: 'Started' },
	{ id: 2, text: 'Completed' },
	{ id: 3, text: 'Closed' },
	{ id: 4, text: 'Completion Approval Requested' },
	{ id: 6, text: 'Rejected' }
];

export const USER_STATUS = [ { id: 0, text: 'Inactive' }, { id: 1, text: 'Active' }, { id: 2, text: 'Banned' } ];

export const UNIT_STATUS = [
	{ id: 0, text: 'Available' },
	{ id: 1, text: 'Unit Sold' },
	{ id: 2, text: 'Completed' },
	{ id: 3, text: 'Closed' },
	{ id: 4, text: 'Completion Approval Requested' },
	{ id: 6, text: 'Rejected' }
];
export const PAYMENT_LINK_EXPIRY_DAYS = 14;

export const BASE_URL = 'https://api.eservice-falconcity.com/api/';
