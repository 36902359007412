// import store from "@/state/store";
function routeGuard(to, from, next) {
    let isLoggedIn = false;
    localStorage.getItem("cs_user_token")
        ? (isLoggedIn = true)
        : (isLoggedIn = false);
    isLoggedIn ? next() : next("/login");
}
export default [
    {
        path: "/",
        name: "home",
        beforeEnter: routeGuard,
        meta: {
            authRequired: true,
        },
        component: () => import("../views/pages/dashboard/index"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("../views/pages/account/login"),
    },
    {
        path: "/register",
        name: "register",
        component: () => import("../views/pages/account/register"),
    },
    {
        path: "/forgot-password",
        name: "Forgot-password",
        component: () => import("../views/pages/account/forgot-password"),
    },
    {
        path: "/logout",
        name: "logout",
    },

    {
        path: "/chat",
        name: "Chat",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/pages/chat/index"),
    },
    {
        path: "/calendar",
        name: "Calendar",
        meta: {
            authRequired: true,
        },
        component: () => import("../views/pages/calendar/index"),
    },
    {
        path: "/ecommerce/products",
        name: "products",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/products"),
    },
    {
        path: "/ecommerce/product-detail",
        name: "product detail",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/product-detail"),
    },
    {
        path: "/ecommerce/orders",
        name: "Orders",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/orders"),
    },
    {
        path: "/ecommerce/customers",
        name: "Customers",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/customers"),
    },
    {
        path: "/ecommerce/cart",
        name: "Cart",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/cart"),
    },
    {
        path: "/ecommerce/checkout",
        name: "Checkout",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/checkout"),
    },
    {
        path: "/ecommerce/shops",
        name: "Shops",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/shops"),
    },
    {
        path: "/ecommerce/add-product",
        name: "Add-product",
        meta: { authRequired: true },
        component: () => import("../views/pages/ecommerce/add-product"),
    },
    {
        path: "/email/inbox",
        name: "Inbox",
        meta: { authRequired: true },
        component: () => import("../views/pages/email/inbox"),
    },
    {
        path: "/email/read",
        name: "Read Email",
        meta: { authRequired: true },
        component: () => import("../views/pages/email/reademail"),
    },
    {
        path: "/auth/login-1",
        name: "login-1",
        meta: { authRequired: true },
        component: () => import("../views/pages/sample-auth/login-1"),
    },
    {
        path: "/auth/register-1",
        name: "register-1",
        meta: { authRequired: true },
        component: () => import("../views/pages/sample-auth/register-1"),
    },
    {
        path: "/auth/lock-screen-1",
        name: "Lock-screen-1",
        meta: { authRequired: true },
        component: () => import("../views/pages/sample-auth/lock-screen-1"),
    },
    {
        path: "/auth/recoverpwd-1",
        name: "Recoverpwd-1",
        meta: { authRequired: true },
        component: () => import("../views/pages/sample-auth/recoverpwd-1"),
    },
    {
        path: "/apps/kanban-board",
        name: "Kanban-board",
        meta: { authRequired: true },
        component: () => import("../views/pages/kanbanboard/index"),
    },
    {
        path: "/pages/starter",
        name: "Starter Page",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/starter"),
    },
    {
        path: "/pages/maintenance",
        name: "Maintenance",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/maintenance"),
    },
    {
        path: "/pages/coming-soon",
        name: "Coming-soon",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/coming-soon"),
    },
    {
        path: "/pages/timeline",
        name: "Timeline",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/timeline/index"),
    },
    {
        path: "/pages/faqs",
        name: "Faqs",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/faqs"),
    },
    {
        path: "/pages/pricing",
        name: "Pricing",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/pricing/index"),
    },
    {
        path: "/pages/error-404",
        name: "Error-404",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/error-404"),
    },
    {
        path: "/pages/error-500",
        name: "Error-500",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/error-500"),
    },
    {
        path: "/icons/font-awesome",
        name: "Font Awesome 5",
        meta: { authRequired: true },
        component: () => import("../views/pages/icons/font-awesome/index"),
    },
    {
        path: "/icons/dripicons",
        name: "Dripicons",
        meta: { authRequired: true },
        component: () => import("../views/pages/icons/dripicons"),
    },
    {
        path: "/icons/material-design",
        name: "Material Design",
        meta: { authRequired: true },
        component: () => import("../views/pages/icons/materialdesign/index"),
    },
    {
        path: "/icons/remix",
        name: "Remix Icons",
        meta: { authRequired: true },
        component: () => import("../views/pages/icons/remix/index"),
    },
    {
        path: "/ui/buttons",
        name: "Buttons",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/buttons"),
    },
    {
        path: "/ui/alerts",
        name: "Alerts",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/alerts"),
    },
    {
        path: "/ui/grid",
        name: "Grid",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/grid"),
    },
    {
        path: "/ui/cards",
        name: "Cards",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/cards"),
    },
    {
        path: "/ui/carousel",
        name: "Carousel",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/carousel"),
    },
    {
        path: "/ui/dropdowns",
        name: "Dropdowns",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/dropdowns"),
    },
    {
        path: "/ui/images",
        name: "Images",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/images"),
    },
    {
        path: "/ui/modals",
        name: "Modals",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/modals"),
    },
    {
        path: "/ui/rangeslider",
        name: "Range - slider",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/rangeslider"),
    },
    {
        path: "/ui/progressbar",
        name: "Progressbar",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/progressbars"),
    },
    {
        path: "/ui/sweet-alert",
        name: "Sweet-alert",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/sweet-alert"),
    },
    {
        path: "/ui/tabs-accordion",
        name: "Tabs & Accordion",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/tabs-accordions"),
    },
    {
        path: "/ui/typography",
        name: "Typography",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/typography"),
    },
    {
        path: "/ui/video",
        name: "Video",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/video"),
    },
    {
        path: "/ui/general",
        name: "General",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/general"),
    },
    {
        path: "/ui/lightbox",
        name: "Lightbox",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/lightbox"),
    },
    {
        path: "/ui/notification",
        name: "Notification",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/notification"),
    },
    {
        path: "/ui/rating",
        name: "Rating",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/rating"),
    },
    {
        path: "/ui/session-timeout",
        name: "Session Timeout",
        meta: { authRequired: true },
        component: () => import("../views/pages/ui/session-timeout"),
    },
    {
        path: "/form/elements",
        name: "Form Elements",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/elements"),
    },
    {
        path: "/form/validation",
        name: "Form validation",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/validation"),
    },
    {
        path: "/form/advanced",
        name: "Form Advanced",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/advanced"),
    },
    {
        path: "/form/editor",
        name: "CK Editor",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/ckeditor"),
    },
    {
        path: "/form/uploads",
        name: "File Uploads",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/uploads"),
    },
    {
        path: "/form/wizard",
        name: "Form Wizard",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/wizard"),
    },
    {
        path: "/form/mask",
        name: "Form Mask",
        meta: { authRequired: true },
        component: () => import("../views/pages/forms/mask"),
    },
    {
        path: "/tables/basic",
        name: "Basic Tables",
        meta: { authRequired: true },
        component: () => import("../views/pages/tables/basictable"),
    },
    {
        path: "/tables/advanced",
        name: "Advanced Tables",
        meta: { authRequired: true },
        component: () => import("../views/pages/tables/advancedtable"),
    },
    {
        path: "/charts/apex",
        name: "Apex chart",
        meta: { authRequired: true },
        component: () => import("../views/pages/charts/apex"),
    },
    {
        path: "/charts/chartjs",
        name: "Chartjs chart",
        meta: { authRequired: true },
        component: () => import("../views/pages/charts/chartjs/index"),
    },
    {
        path: "/charts/chartist",
        name: "Chartist chart",
        meta: { authRequired: true },
        component: () => import("../views/pages/charts/chartist"),
    },
    {
        path: "/charts/echart",
        name: "Echart chart",
        meta: { authRequired: true },
        component: () => import("../views/pages/charts/echart/index"),
    },
    {
        path: "/maps/google",
        name: "Google Maps",
        meta: { authRequired: true },
        component: () => import("../views/pages/maps/google"),
    },
    {
        path: "/maps/leaflet",
        name: "Leaflet Maps",
        meta: { authRequired: true },
        component: () => import("../views/pages/maps/leaflet/index"),
    },
    {
        path: "/registration",
        name: "New Registration",
        meta: { authRequired: true },
        component: () => import("../views/pages/account/registration.vue"),
    },
    {
        path: "/circulars",
        name: "Circulars",
        meta: { authRequired: true },
        component: () => import("../views/pages/circulars/circulars.vue"),
    },
    {
        path: "/image-preview",
        name: "imagePreview",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/image-preview.vue"),
    },
    {
        path: "/pdf-preview",
        name: "pdfPreview",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/pdf-preview.vue"),
    },
    {
        path: "/mortgage",
        name: "mortgage",
        meta: { authRequired: true },
        component: () => import("../views/pages/mortgage/mortgage.vue"),
    },
    {
        path: "/new-maintenance",
        name: "maintenance",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/maintenance/newMaintenance.vue"),
    },
    {
        path: "/new-immigration",
        name: "immigration",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/immigration/newImmigration.vue"),
    },
    {
        path: "/new-titledeed",
        name: "titledeed",
        meta: { authRequired: true },
        component: () => import("../views/pages/title-deed/newTitleDeed.vue"),
    },
    {
        path: "/new-interim",
        name: "interim",
        meta: { authRequired: true },
        component: () => import("../views/pages/interim/newInterim.vue"),
    },
    {
        path: "/deposit-refund",
        name: "securityDepositRefund",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/security-deposit-refund/newSecurityDepositRefund.vue"
            ),
    },
    {
        path: "/noc-revalidation",
        name: "nocRevalidation",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/noc-revalidation/newNocRevalidation.vue"),
    },
    {
        path: "/built-drawings",
        name: "builtDrawing",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/villa-as-drawing/villaAsBuiltInDrawingRequest.vue"
            ),
    },
    {
        path: "/trash-bin",
        name: "trashBin",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/trash-bin/newTrashBinRequest.vue"),
    },
    {
        path: "/barrier-control",
        name: "barrierControl",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/barrier-control/newBarrierRemoteControlRequest.vue"
            ),
    },
    {
        path: "/amendment",
        name: "amendment",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/amendments/newAmendmentRequest.vue"),
    },
    {
        path: "/landscaping",
        name: "landscape",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/landscaping/newLandscapingRequest.vue"),
    },
    {
        path: "/home-improvement",
        name: "homeImprovement",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/home-improvement/newHomeImprovementRequest.vue"
            ),
    },
    {
        path: "/commerical-mod",
        name: "commercialModification",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/commercial-modification/newCommercialModification.vue"
            ),
    },

    {
        path: "/modification-list",
        name: "commercialModificationList",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/commercial-modification/commercialModificationList.vue"
            ),
    },

    {
        path: "/modification/:id",
        name: "commercialModificationDetails",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/commercial-modification/commercialModificationDetails.vue"
            ),
    },

    {
        path: "/new/payment/:requestTypeId/:requestId",
        name: "payment",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/payment/paymentConfirmation.vue"),
    },

    {
        path: "/pending-payments",
        name: "pendingPayment",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/payment/pendingPaymentList.vue"),
    },
    {
        path: "/create-circular",
        name: "createCircular",
        meta: { authRequired: true },
        component: () => import("../views/pages/circulars/createCircular.vue"),
    },
    {
        path: "/create-log",
        name: "createVisitorLog",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/visitor-logs/newVisitorLog.vue"),
    },
    {
        path: "/log-list",
        name: "visitorLogList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/visitor-logs/visitorLogList.vue"),
    },
    {
        path: "/visitor-log/:id",
        name: "visitorLogDetails",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/visitor-logs/visitorLogDetails.vue"),
    },
    {
        path: "/immigration/:id",
        name: "immigrationDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/immigration/immigrationDetails.vue"),
    },
    {
        path: "/immigration-list",
        name: "immigrationList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/immigration/immigrationList.vue"),
    },
    {
        path: "/titledeed/:id",
        name: "titleDeedDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/title-deed/titleDeedDetails.vue"),
    },
    {
        path: "/titledeed-list",
        name: "titleDeedList",
        meta: { authRequired: true },
        component: () => import("../views/pages/title-deed/titleDeedList.vue"),
    },
    {
        path: "/barrier-control/:id",
        name: "barrierControlDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/barrier-control/barrierControlDetails.vue"),
    },
    {
        path: "/barrier-control-list",
        name: "barrierControlList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/barrier-control/barrierControlList.vue"),
    },
    {
        path: "/mortgage/:id",
        name: "mortgageDetail",
        meta: { authRequired: true },
        component: () => import("../views/pages/mortgage/mortgageDetails.vue"),
    },
    {
        path: "/mortgage-list",
        name: "mortgageList",
        meta: { authRequired: true },
        component: () => import("../views/pages/mortgage/mortgageList.vue"),
    },
    {
        path: "/amendment/:id",
        name: "amendmentDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/amendments/amendmentDetails.vue"),
    },
    {
        path: "/amendment/print/:id",
        name: "amendmentPrint",
        meta: { authRequired: true },
        component: () => import("../views/pages/amendments/printAmendment.vue"),
    },
    {
        path: "/amendment/gift/:id",
        name: "amendmentGiftPrint",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/amendments/printGiftAmendment.vue"),
    },
    {
        path: "/amendment/sale/:id",
        name: "amendmentSalePrint",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/amendments/printSalesAmendment.vue"),
    },
    {
        path: "/amendments",
        name: "amendmentList",
        meta: { authRequired: true },
        component: () => import("../views/pages/amendments/amendmentList.vue"),
    },
    {
        path: "/interim/:id",
        name: "interimDetail",
        meta: { authRequired: true },
        component: () => import("../views/pages/interim/interimDetails.vue"),
    },
    {
        path: "/interim-list",
        name: "interimList",
        meta: { authRequired: true },
        component: () => import("../views/pages/interim/interimList.vue"),
    },
    {
        path: "/home-improvement/:id",
        name: "homeImprovementDetail",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/home-improvement/homeImprovementDetails.vue"
            ),
    },
    {
        path: "/home-improvements",
        name: "homeImprovementList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/home-improvement/homeImprovementList.vue"),
    },
    {
        path: "/land-scaping/:id",
        name: "landScapeDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/landscaping/landscapingDetails.vue"),
    },
    {
        path: "/landscape-list",
        name: "landScapeList",
        meta: { authRequired: true },
        component: () => import("../views/pages/landscaping/landscapeList.vue"),
    },
    {
        path: "/security-refund/:id",
        name: "refundDetail",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/security-deposit-refund/securityDepositRefundDetails.vue"
            ),
    },
    {
        path: "/refund-list",
        name: "refundList",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/security-deposit-refund/securityDepositList.vue"
            ),
    },
    {
        path: "/noc-revalidation/:id",
        name: "nocRevalidationDetail",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/noc-revalidation/nocRevalidationDetails.vue"
            ),
    },

    {
        path: "/noc-list",
        name: "nocRevalidationList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/noc-revalidation/nocRevalidationList.vue"),
    },
    {
        path: "/built-drawings/:id",
        name: "builtDrawingDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/villa-as-drawing/builtDrawingDetails.vue"),
    },
    {
        path: "/drawings-list",
        name: "builtDrawingDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/villa-as-drawing/builtDrawingsList.vue"),
    },
    {
        path: "/trash-bin/:id",
        name: "trashBinDetail",
        meta: { authRequired: true },
        component: () => import("../views/pages/trash-bin/trashBinDetails.vue"),
    },
    {
        path: "/trash-bin-list",
        name: "trashBinList",
        meta: { authRequired: true },
        component: () => import("../views/pages/trash-bin/trashBinList.vue"),
    },
    {
        path: "/maintenance/:id",
        name: "maintenanceDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/maintenance/maintenanceDetails.vue"),
    },
    {
        path: "/maintenance/print/:id",
        name: "maintenanceDetail",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/maintenance/printMaintenaceRequest.vue"),
    },
    {
        path: "/maintenance-list",
        name: "maintenanceList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/maintenance/maintenanceList.vue"),
    },
    {
        path: "/profile/:id",
        name: "profile",
        meta: { authRequired: true },
        component: () => import("../views/pages/profile/profileDetails.vue"),
    },
    {
        path: "/pages/thank-you",
        name: "thank-you",
        meta: { authRequired: true },
        component: () => import("../views/pages/utility/thank-you.vue"),
    },

    {
        path: "/users",
        name: "users",
        meta: { authRequired: true },
        component: () => import("../views/pages/user/userList.vue"),
    },

    {
        path: "/projects",
        name: "projects",
        meta: { authRequired: true },
        component: () => import("../views/pages/projects/projectList.vue"),
    },
    {
        path: "/project/:id",
        name: "projectDetails",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/projects/projectUnitsDetails.vue"),
    },
    {
        path: "/under-warranty-list",
        name: "underWarrantyList",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/under-warranty-units/underUnitWarrantyList.vue"
            ),
    },
    {
        path: "/new-under-warranty",
        name: "newUnderWarrantyUnit",
        meta: { authRequired: true },
        component: () =>
            import(
                "../views/pages/under-warranty-units/newUnderWarrantyUnit.vue"
            ),
    },
    {
        path: "/terms-and-conditions",
        name: "termsAndConditions",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/terms&conditions/termsAndConditions.vue"),
    },
    {
        path: "/privacy-and-policy",
        name: "privacyAndPolicy",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/privacy&Policy/privacyAndPolicy.vue"),
    },
    {
        path: "/payment-links",
        name: "newPaymentLink",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/payment-links/new-payment-link.vue"),
    },
    {
        path: "/payment-links-list",
        name: "paymentLinksList",
        meta: { authRequired: true },
        component: () =>
            import("../views/pages/payment-links/payment-link-list.vue"),
    },

    {
        path: "/payment-link",
        name: "paymentLinkDetails",
        component: () =>
            import("../views/pages/payment-links/payment-link-details.vue"),
        props: true,
    },
];
