import { BASE_URL } from "../../views/common";
import axios from "axios";

export const state = {
    projects: [],
    countries: [],
    groups: [],
    requestData: [],
    requestStaffList: [],
    pendingPaymentList: [],
    userInfo: null,
    profilePic: null,
    sideBarCollapsed: false,
    paymentData: null,
    allStaffs: [],
    allOwners: [],
    allTenants: [],
    visitorsList: [],
    userCount: {
        staffs: 0,
        owners: 0,
        tenants: 0,
    },
    mortgageList: [],
    amendmentList: [],
    maintenanceList: [],
    immigrationList: [],
    titleDeedList: [],
    interimList: [],
    improvementList: [],
    lanscapeList: [],
    refundList: [],
    nocList: [],
    drawingList: [],
    trashBinList: [],
    modificationList: [],
    barrierList: [],
    projectList: [],
    underWarrantyList: [],
    unitsForWarranty: [],
    level: null,
    paymentLinks: [],
};

export const getters = {
    getCountries: (state) => state.countries,

    getProjects: (state) => state.projects,

    getGroups: (state) => state.groups,

    getRequets: (state) => state.requestData,

    getRequestStaff: (state) => state.requestStaffList,

    getUserInfo: (state) => state.userInfo,

    getProfileImage: (state) => state.profilePic,

    getSidebarStatus: (state) => state.sideBarCollapsed,

    getPaymentData: (state) => state.paymentData,

    getPendingPaymentList: (state) => state.pendingPaymentList,

    getAllStaffs: (state) => state.allStaffs,

    getAllOwners: (state) => state.allOwners,

    getAllTenants: (state) => state.allTenants,

    getUserCount: (state) => state.userCount,

    getVisitorsList: (state) => state.visitorsList,

    getMortgageList: (state) => state.mortgageList,

    getAmendmentList: (state) => state.amendmentList,

    getMaintenanceList: (state) => state.maintenanceList,

    getImmigrationList: (state) => state.immigrationList,

    getTitleDeedList: (state) => state.titleDeedList,

    getInterimList: (state) => state.interimList,

    getImprovementList: (state) => state.improvementList,

    getLandscapeList: (state) => state.lanscapeList,

    getRefundList: (state) => state.refundList,

    getNocList: (state) => state.nocList,

    getDrawingsList: (state) => state.drawingList,

    getTrashBinList: (state) => state.trashBinList,

    getModificationList: (state) => state.modificationList,

    getBarrierControlList: (state) => state.barrierList,

    getProjectList: (state) => state.projectList,

    getUnderWarrantyList: (state) => state.underWarrantyList,

    getUnitsForWarranty: (state) => state.unitsForWarranty,

    getLevel: (state) => state.level,

    getPaymentLinks: (state) => state.paymentLinks,
};

// synchronous way to update state
export const mutations = {
    setCountries(state, payload) {
        state.countries = payload;
    },
    setProjects(state, payload) {
        state.projects = payload;
    },
    setGroups(state, payload) {
        state.groups = payload;
    },
    setRequests(state, payload) {
        state.requestData = payload;
    },
    setStaffList(state, payload) {
        state.requestStaffList = payload;
    },
    setPendingPayments(state, payload) {
        state.pendingPaymentList = payload;
    },
    setUserInfo(state, payload) {
        state.userInfo = payload;
    },

    setProfilePic(state, payload) {
        state.profilePic = payload;
    },

    setSideBar(state, payload) {
        state.sideBarCollapsed = payload;
    },

    setPaymentBody(state, payload) {
        state.paymentData = payload;
    },

    setAllStaffs(state, payload) {
        state.allStaffs = payload;
    },

    setAllOwners(state, payload) {
        state.allOwners = payload;
    },

    setAllTenants(state, payload) {
        state.allTenants = payload;
    },
    setUserCounts(state, payload) {
        state.userCount = payload;
    },
    setVisitorsList(state, payload) {
        state.visitorsList = payload;
    },

    setMortgageList(state, payload) {
        state.mortgageList = payload;
    },
    setAmendmentList(state, payload) {
        state.amendmentList = payload;
    },
    setMaintenanceList(state, payload) {
        state.maintenanceList = payload;
    },

    setImmigrationList(state, payload) {
        state.immigrationList = payload;
    },
    setTitleDeedList(state, payload) {
        state.titleDeedList = payload;
    },

    setInterimList(state, payload) {
        state.interimList = payload;
    },
    setImprovementList(state, payload) {
        state.improvementList = payload;
    },
    setLandscapingList(state, payload) {
        state.lanscapeList = payload;
    },
    setRefundList(state, payload) {
        state.refundList = payload;
    },
    setNocList(state, payload) {
        state.nocList = payload;
    },
    setDrawingList(state, payload) {
        state.drawingList = payload;
    },
    setTrashBinList(state, payload) {
        state.trashBinList = payload;
    },
    setModificationList(state, payload) {
        state.modificationList = payload;
    },
    setBarrierControlList(state, payload) {
        state.barrierList = payload;
    },
    setUnderWarrantyList(state, payload) {
        state.underWarrantyList = payload;
    },
    setWarrantyUnits(state, payload) {
        state.unitsForWarranty = payload;
    },
    setLevel(state, payload) {
        state.level = payload;
    },
    setPaymentLinks(state, payload) {
        state.paymentLinks = payload;
    },
};

//asynchronous way to updtae a state
export const actions = {
    fetchCountries(state) {
        axios.get(`${BASE_URL}countries`).then((res) => {
            state.commit("setCountries", res.data.countries);
        });
    },
    fetchProjects(state) {
        if (localStorage.cs_user_token) {
            axios
                .get(
                    `${BASE_URL}projects?token=${localStorage.cs_user_token}&offset=0&limit=50`
                )
                .then((res) => {
                    state.commit("setProjects", res.data.response);
                });
        }
    },
    fetchGroups(state) {
        if (localStorage.cs_user_token) {
            axios
                .get(`${BASE_URL}groups?token=${localStorage.cs_user_token}`)
                .then((res) => {
                    state.commit("setGroups", res.data.response);
                });
        }
    },

    fetchRequests(state, token) {
        return axios.get(`${BASE_URL}request?token=${token}`).then((res) => {
            const requests = res.data.data.request.filter((val) => {
                return (
                    val.requesttype != 26 &&
                    val.requesttype != 10 &&
                    val.requesttype != 13 &&
                    val.requesttype != 11 &&
                    val.requesttype != 16 &&
                    val.requesttype != 18 &&
                    val.requesttype != 19 &&
                    val.requesttype != 9 &&
                    val.requesttype != 8
                );
            });
            state.commit("setRequests", requests);
            state.commit("setStaffList", res.data.data.users);
            state.commit("setLevel", res.data.data.level);
        });
    },

    fetchPendingPayments(state, token) {
        axios.get(`${BASE_URL}pendingpayments?token=${token}`).then((res) => {
            state.commit("setPendingPayments", res.data.data.request);
        });
    },

    fetchUserInfo(state, token) {
        axios.get(`${BASE_URL}dashboard?token=${token}`).then((res) => {
            state.commit("setUserInfo", res.data.user_info);
            localStorage.setItem(
                "user_info",
                JSON.stringify(res.data.user_info)
            );
            axios
                .get(
                    `${BASE_URL}${res.data.user_info.profile_pic_url}/?token=${token}`
                )
                .then((res) => {
                    // console.log("the base64 url is ", res.data.file);
                    localStorage.setItem("profile_pic", res.data.file);
                    state.commit("setProfilePic", res.data.file);
                });
        });
    },

    fetchProfileImage(state, url) {
        axios
            .get(`${BASE_URL}${url}/?token=${localStorage.cs_user_token}`)
            .then((res) => {
                localStorage.setItem("profile_pic", res.data.file);
                state.commit("setProfilePic", res.data.file);
            });
    },

    fetchAllStaffs(state, token) {
        axios.get(`${BASE_URL}staffs?token=${token}`).then((res) => {
            state.commit("setAllStaffs", res.data.staffs);
            state.commit("setUserCounts", res.data.total);
        });
    },

    fetchAllOwners(state, token) {
        axios
            .get(`${BASE_URL}owners?token=${token}&offset=0&limit=500`)
            .then((res) => {
                state.commit("setAllOwners", res.data.owners);
                state.commit("setUserCounts", res.data.total);
            });
    },

    fetchAllTenants(state, token) {
        axios
            .get(`${BASE_URL}tenants?token=${token}&offset=0&limit=500`)
            .then((res) => {
                state.commit("setAllTenants", res.data.tenants);
                state.commit("setUserCounts", res.data.total);
            });
    },

    fetchVisitorsList(state, token) {
        axios.get(`${BASE_URL}visitors?token=${token}`).then((res) => {
            console.log(res.data);
            state.commit("setVisitorsList", res.data.response);
        });
    },
    fetchMortgageList(state, offset) {
        return axios
            .get(
                `${BASE_URL}mortgages?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setMortgageList", res.data.response);
            });
    },

    fetchAmendmentList(state, offset) {
        return axios
            .get(
                `${BASE_URL}amendments?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setAmendmentList", res.data.response);
            });
    },

    fetchMaintenanceList(state, offset) {
        return axios
            .get(
                `${BASE_URL}maintenances?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setMaintenanceList", res.data.response);
            });
    },

    fetchImmigrationList(state, offset) {
        return axios
            .get(
                `${BASE_URL}immigrations?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setImmigrationList", res.data.response);
            });
    },
    // fetchTitleDeedList
    fetchTitleDeedList(state, offset) {
        return axios
            .get(
                `${BASE_URL}titledeeds?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setTitleDeedList", res.data.response);
            });
    },

    fetchInterimList(state, offset) {
        return axios
            .get(
                `${BASE_URL}interims?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setInterimList", res.data.response);
            });
    },
    fetchImprovementList(state, offset) {
        return axios
            .get(
                `${BASE_URL}homeimprovements?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setImprovementList", res.data.response);
            });
    },
    fetchLandscapingList(state, offset) {
        return axios
            .get(
                `${BASE_URL}landscaping?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setLandscapingList", res.data.response);
            });
    },
    fetchModificationList(state, offset) {
        return axios
            .get(
                `${BASE_URL}commercialmodifications?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setModificationList", res.data.response);
            });
    },

    fetchRefundList(state, offset) {
        return axios
            .get(
                `${BASE_URL}securitydepositrefunds?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setRefundList", res.data.response);
            });
    },

    fetchNocList(state, offset) {
        return axios
            .get(
                `${BASE_URL}noc-revalidations?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setNocList", res.data.response);
            });
    },

    fetchDrawingsList(state, offset) {
        return axios
            .get(
                `${BASE_URL}built-drawings?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setDrawingList", res.data.response);
            });
    },
    fetchTrashBinList(state, offset) {
        return axios
            .get(
                `${BASE_URL}trash-bins?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setTrashBinList", res.data.response);
            });
    },
    fetchBarrierList(state, offset) {
        return axios
            .get(
                `${BASE_URL}barrier-remote-controls?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setBarrierControlList", res.data.response);
            });
    },

    fetchUnderWarrantyList(state, offset) {
        return axios
            .get(
                `${BASE_URL}underwarrantyunits?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setUnderWarrantyList", res.data.response);
            });
    },

    fetchWarrantyUnits(state) {
        return axios
            .get(
                `${BASE_URL}available-units-for-warranty?token=${localStorage.cs_user_token}`
            )
            .then((res) => {
                state.commit("setWarrantyUnits", res.data.response);
            });
    },

    fetchPaymentLinks(state, offset) {
        axios
            .get(
                `${BASE_URL}payment-links-list?token=${localStorage.cs_user_token}&offset=${offset}&limit=50`
            )
            .then((res) => {
                state.commit("setPaymentLinks", res.data.response);
            });
    },
    toggleSideBar(state, status) {
        state.commit("setSideBar", status);
    },

    setPaymentInfo(state, payload) {
        state.commit("setPaymentBody", payload);
    },

    removeProfilePic(state) {
        state.commit("setProfilePic", null);
    },
};
